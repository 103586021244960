import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { SnsIcons } from 'components/Sns/SnsIcons';

import {
  selectCategories,
  selectFeatures,
  selectTopPageSections,
} from 'lib/util/privateMarketplace';
import { BookingWidgetThemeContext } from 'contexts/BookingWidgetThemeContext';
import { encodeCategoryNameForURI } from 'lib/util/encodeCategoryNameForURI';
import { selectFooterSettings } from 'lib/util/footerSettingsShape';
import { useCustomFooterLinks } from 'hooks/useCustomFooterLinks';
import { ThemeName } from 'components/Themes';
import { useEssentialPageLinks } from 'hooks/useEssentialPageLinks';

import styles from './PMPFooterView.module.css';
import { appendQueryString } from 'lib/util/appendQueryString';
import { useQueryString } from 'hooks/useQueryString';

const getIconPath = (bookingWidgetTheme: ThemeName, iconKey: string): string => {
  switch (bookingWidgetTheme) {
    case 'COOL_PROFESSIONAL':
      return `/static/images/coolProfessional/ic_${iconKey}.svg`;
    default:
      return `/static/images/ic_${iconKey}.svg`;
  }
};

const PMPFooterView = () => {
  const { i18n, t } = useTranslation();

  const bookingWidgetTheme = React.useContext(BookingWidgetThemeContext);

  const settings = useSelector(selectFooterSettings);
  const categories = useSelector(selectCategories);
  const features = useSelector(selectFeatures);
  const topPageSections = useSelector(selectTopPageSections);

  const customFooterLinks = useCustomFooterLinks();

  const showPopularProducts = topPageSections.includes('POPULAR_PRODUCTS');
  const showRecommendedProducts = topPageSections.includes('RECOMMENDED_PRODUCTS');
  const showCategory = topPageSections.includes('CATEGORY');
  const showFeature = topPageSections.includes('FEATURE');
  const essentialPageLinks = useEssentialPageLinks();

  const showEssentialPageLinks = bookingWidgetTheme === 'VIBRANT';

  const queryString = useQueryString();

  return (
    <footer className={styles['c-pmp-footer']}>
      <div className={styles['c-pmp-footer__nav']}>
        {showEssentialPageLinks && essentialPageLinks.length > 0 && (
          <div className={styles['c-pmp-footer__nav__box']}>
            <ul className={styles['c-pmp-footer__nav__box__list']}>
              {essentialPageLinks.map((link, idx) => (
                <li className={styles['c-pmp-footer__nav__box__list__item']} key={idx}>
                  <Link href={link.url}>{link.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {(showPopularProducts || showRecommendedProducts) && (
          <div className={styles['c-pmp-footer__nav__box']}>
            <p className={styles['c-pmp-footer__nav__box__ttl']}>{t('Ranking')}</p>
            <ul className={styles['c-pmp-footer__nav__box__list']}>
              {showPopularProducts && (
                <li className={styles['c-pmp-footer__nav__box__list__item']}>
                  <Link href={appendQueryString('/top/popular', queryString)}>
                    {t('Popularity Ranking')}
                  </Link>
                </li>
              )}
              {showRecommendedProducts && (
                <li className={styles['c-pmp-footer__nav__box__list__item']}>
                  <Link href={appendQueryString('/top/recommended', queryString)}>
                    {t('Recommended Products')}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        )}
        {showCategory && (
          <div className={styles['c-pmp-footer__nav__box']}>
            <p className={styles['c-pmp-footer__nav__box__ttl']}>{t('Find by Category')}</p>
            <ul className={styles['c-pmp-footer__nav__box__list']}>
              {categories.map((category, idx) => (
                <li className={styles['c-pmp-footer__nav__box__list__item']} key={idx}>
                  <Link
                    href={appendQueryString(
                      `/top/${encodeCategoryNameForURI(category.name)}`,
                      queryString
                    )}
                  >
                    {category.display_name || category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {showFeature && (
          <div className={styles['c-pmp-footer__nav__box']}>
            <p className={styles['c-pmp-footer__nav__box__ttl']}>{t('Featured')}</p>
            <ul className={styles['c-pmp-footer__nav__box__list']}>
              {features.map((feature, idx) => (
                <li className={styles['c-pmp-footer__nav__box__list__item']} key={idx}>
                  <Link
                    href={appendQueryString(
                      `/top/${encodeCategoryNameForURI(feature.name)}`,
                      queryString
                    )}
                  >
                    {feature.display_name || feature.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
        {customFooterLinks.length > 0 && (
          <div className={styles['c-pmp-footer__custom__links']}>
            <div className={styles['c-pmp-footer__nav__box']}>
              <ul className={styles['c-pmp-footer__nav__box__list']}>
                {customFooterLinks.map((footerLink, idx) => (
                  <li className={styles['c-pmp-footer__nav__box__list__item']} key={idx}>
                    {footerLink.custom_page_path ? (
                      <Link href={appendQueryString(footerLink.custom_page_path, queryString)}>
                        {footerLink.text}
                      </Link>
                    ) : (
                      <a href={footerLink.url} target="_blank" rel="noopener noreferrer">
                        {footerLink.text}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className={styles['c-pmp-footer__info_sns_icons']}>
        <div className={styles['c-pmp-footer__info']}>
          <div className={styles['c-pmp-footer__info__section']}>
            <p>
              <img src={getIconPath(bookingWidgetTheme, 'phone')} alt="Phone" />
              <span>{settings.phone}</span>
            </p>
            <p>
              <img src={getIconPath(bookingWidgetTheme, 'mail')} alt="Mail" />
              <span>{settings.email}</span>
            </p>
          </div>
          <div className={styles['c-pmp-footer__info__section']}>
            <p>
              <img src={getIconPath(bookingWidgetTheme, 'location')} alt="Location" />
              <span>{settings.address}</span>
            </p>
            <p>
              <img src={getIconPath(bookingWidgetTheme, 'time')} alt="Time" />
              <span>
                {t('Hours: {{operatingHoursDescription}}', {
                  operatingHoursDescription: settings.operatingHoursDescription,
                })}
              </span>
            </p>
          </div>
        </div>
        <SnsIcons snsIds={settings?.snsIds} />
      </div>
      <div className={styles['c-pmp-footer__rule']}>
        {i18n.language === 'ja-JP' && settings.showJapaneseTerms && (
          <ul className={styles['c-pmp-footer__rule__list']}>
            <li>
              <a href="https://app.ntmg.com/ja/terms" target="_blank" rel="noopener noreferrer">
                特定商取引法
              </a>
            </li>
          </ul>
        )}
      </div>
      <p className={styles['c-pmp-footer__copy']}>© 2022 NutmegLabs Inc.</p>
    </footer>
  );
};

export default PMPFooterView;
